import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Container from "../block/container"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { A11y, Autoplay, Pagination } from "swiper/core"
import TestimonialStrip from "../../assets/images/twisted-strip-testimonial.png"
import "swiper/swiper.scss"
import "swiper/swiper-bundle.css"
import "swiper/components/a11y/a11y.min.css"
import "swiper/components/pagination/pagination.min.css"
import "../../styles/components/block--testimonials.scss"

SwiperCore.use([Pagination, A11y, Autoplay])

const BlockTestimonials = () => {
  return (
    <Container
      classes="block--testimonials"
      title="Trusted by 1,000+ fast-growing teams"
    >
      <div className="block--slider-decoration">
        <img className="slider-strip" src={TestimonialStrip} alt="" />

        <Swiper
          loop={true}
          autoplay={{ delay: 5000 }}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <StaticImage
              as="figure"
              className="field field--image"
              src="../../assets/images/testimonial-image.jpg"
              quality="90"
              placeholder="blurred"
              alt="Testimonial image"
              title="Testimonial image"
            />
            <blockquote>
              <p>
                Our share of missing invoices went down from 50% to 5% without
                having to run after my colleagues. Spendesk saves me so much
                time, i can finally focus on high value tasks.
              </p>
              <cite>
                <span>
                  <strong>Mark Lodge</strong>
                </span>
                <span>Director of Business Planning - NextBigThing</span>
              </cite>
            </blockquote>
          </SwiperSlide>

          <SwiperSlide>
            <StaticImage
              as="figure"
              className="field field--image"
              src="../../assets/images/testimonial-image.jpg"
              quality="90"
              placeholder="blurred"
              alt="Testimonial image"
              title="Testimonial image"
            />
            <blockquote>
              <p>
                Our share of missing invoices went down from 50% to 5% without
                having to run after my colleagues. Spendesk saves me so much
                time, i can finally focus on high value tasks.
              </p>
              <cite>
                <span>
                  <strong>Mark Lodge</strong>
                </span>
                <span>Director of Business Planning - NextBigThing</span>
              </cite>
            </blockquote>
          </SwiperSlide>

          <SwiperSlide>
            <StaticImage
              as="figure"
              className="field field--image"
              src="../../assets/images/testimonial-image.jpg"
              quality="90"
              placeholder="blurred"
              alt="Testimonial image"
              title="Testimonial image"
            />
            <blockquote>
              <p>
                Our share of missing invoices went down from 50% to 5% without
                having to run after my colleagues. Spendesk saves me so much
                time, i can finally focus on high value tasks.
              </p>
              <cite>
                <span>
                  <strong>Mark Lodge</strong>
                </span>
                <span>Director of Business Planning - NextBigThing</span>
              </cite>
            </blockquote>
          </SwiperSlide>

          <SwiperSlide>
            <StaticImage
              as="figure"
              className="field field--image"
              src="../../assets/images/testimonial-image.jpg"
              quality="90"
              placeholder="blurred"
              alt="Testimonial image"
              title="Testimonial image"
            />
            <blockquote>
              <p>
                Our share of missing invoices went down from 50% to 5% without
                having to run after my colleagues. Spendesk saves me so much
                time, i can finally focus on high value tasks.
              </p>
              <cite>
                <span>
                  <strong>Mark Lodge</strong>
                </span>
                <span>Director of Business Planning - NextBigThing</span>
              </cite>
            </blockquote>
          </SwiperSlide>
        </Swiper>
      </div>
    </Container>
  )
}

export default BlockTestimonials
